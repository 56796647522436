@media screen and (min-width: 768px) {
    .payment .profile__questions-back {
        display: flex;
    }
}

.payment__header {
    padding: 80px 0;
    background-color: #0f84f0;
    display: flex;
    justify-content: center;

    &-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 18px;

        color: white;
    }

    &__posts {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 21px;
        gap: 16px;

        &-button {
            width: 71px;
            height: 71px;
            background: #ffffff;
            border-radius: 15px;

            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 61px;
            display: flex;
            align-items: center;
            justify-content: center;

            color: #000000;
            transition: all 550ms linear;

            &.active {
                filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.25));
            }

            &.not-active {
                filter: blur(5px);
            }
        }
    }
}

.payment__content {
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    margin-top: -10px;

    @media screen and (min-width: 768px) {
        max-width: 345px;
        margin: 0 auto;
    }

    &-block {
        padding-top: 35px;
    }

    &-info {
        display: flex;
        justify-content: space-between;

        &-title {
            background: #0f84f0;
            border-radius: 18px;
            padding: 0 9px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            &-text {
                max-width: 129px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;

                color: #ffffff;
            }
        }

        &-number {
            background: #0f84f0;
            border-radius: 18px;
            padding: 0 19px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            &-text {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 30px;
                display: flex;
                align-items: center;
                text-align: center;

                color: #ffffff;
            }
        }
    }

    &-number {
        display: flex;
        gap: 30px;

        &-input {
            width: 100%;
            padding: 7px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);

            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;

            color: #000000;

            &:focus {
                outline: none;
            }
        }
    }

    &-sum {
        margin-top: 30px;

        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            margin-bottom: 7px;
        }

        &-block {
            display: flex;
            align-items: center;
            gap: 15px;
        }

        &-input {
            width: 100%;
            padding: 7px;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);

            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 43px;

            color: #000000;

            &:focus {
                outline: none;
            }

            &-label {
                width: 50%;
                position: relative;
            }

            &-text {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
        }

        &-variant {
            display: flex;
            gap: 10px;
            width: 50%;

            &-button {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;

                color: #000000;
                padding: 8px 0;
                width: 100%;
                background: #ffffff;
                border: 2px solid #0f84f0;
                border-radius: 20px;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
        }
    }

    &-pay {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        text-align: center;

        color: #ffffff;
        padding: 13px 0;
        background: #0f84f0;
        border-radius: 25px;
        width: 100%;
        margin-top: 60px;
        transition: all 250ms linear;
    }
}
