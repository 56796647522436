.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    z-index: 3;

    @media screen and (min-width: 768px) {
        background: #0f84f0;
        top: 0;
        bottom: unset;
    }
}

.footer__menu {
    display: flex;
    justify-content: space-between;

    &-desk {
        display: none;
    }

    &-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &:active {
            appearance: none;
            outline: none;
            background: none;
        }

        &-icon {
            width: 32px;
            height: 26px;
            fill: #9f9fa9;
            transition: fill 250ms linear;
        }

        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;

            color: #9f9fa9;
            transition: color 250ms linear;
        }

        &.active &-title {
            color: #0f84f0;
        }
        &.active &-icon {
            fill: #0f84f0;
        }
    }
}

@media screen and (min-width: 768px) {
    .footer__menu {
        display: none;
    }
    .footer__menu-desk {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-item {
        }

        &-profile {
            &-link {
                padding: 7px 19px;
                &.active {
                    border-radius: 20px;
                    border: 3px solid #fff;
                    background: #0f84f0;
                    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
                }
            }
        }
        &-link {
            display: flex;
            padding: 7px 19px;
            &-title {
                color: #fff;
                font-family: Montserrat;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            &.active {
                border-radius: 20px;
                border: 3px solid #fff;
                background: #0f84f0;
                box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
            }

            &-icon {
                width: 32px;
                height: 26px;
                fill: white;
                transition: fill 250ms linear;
            }
        }

        &-profile {
            &-link {
                display: flex;
                gap: 5px;

                &-title {
                    color: #fff;
                    font-family: Montserrat;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}
