.select-lang {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
        max-width: 345px;
        margin: 0 auto;
    }

    &-button {
        padding: 9px 10px;
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        border-radius: 15px;
        border: 3px solid #fff;
        background: #0f84f0;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.4);

        &.active {
            border-radius: 15px;
            border: 3px solid #fff;
            background: #0f84f0;
            box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
        }
    }
}
