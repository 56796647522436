.number-phone {
    padding-top: 80px;

    &__block {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }

    &-desk {
        &__block {
            max-width: 450px;
            margin: 0 auto;

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-content {
            border-radius: 12px;
            background: #fff;
            box-shadow: 1px 1px 7px 7px rgba(0, 0, 0, 0.1);
            padding: 30px;

            &-title {
                color: #000;
                font-family: Montserrat;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 23px;
            }

            &-second {
                color: #000;
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 32px;
            }

            &-notification {
                border-radius: 12px;
                background: #e9f5ff;
                color: #000;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 15px 18px;
                margin-bottom: 18px;
            }

            &-password {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                &-block {
                    &-input {
                        color: #000;
                        font-family: Montserrat;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        border-radius: 12px;
                        border: 1px solid #9f9fa9;
                        padding: 21px 50px 21px 16px;
                    }
                }
            }
        }
    }

    &__title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        text-align: center;

        color: #ffffff;
        margin-bottom: 37px;
    }

    &__second {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        text-align: center;

        color: #ffffff;
        margin-bottom: 37px;
    }

    &__form {
        display: flex;
        flex-direction: column;

        &-desk {
            &-phone {
                border-radius: 12px;
                border: 2px solid #9f9fa9;
                background: #fff;
                width: 100%;
                padding: 19px 17px;
                box-sizing: border-box;

                color: #5c5c68;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                &:focus {
                    outline: none;
                    border: 2px solid #0f84f0;
                }

                &:focus {
                    color: #0f84f0;
                }
            }

            &-error {
                opacity: 0;
                color: #ff1313;
                transition: opacity 250ms linear;
                &.active {
                    opacity: 1;
                }
            }

            &-button {
                border-radius: 40px;
                background: #d9d9d9;
                padding: 19px 0;
                width: 100%;

                color: #5c5c68;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                cursor: pointer;
                transition: background 250ms linear;
                transition-property: color, background;

                &.active {
                    background: #0f84f0;
                    color: #fff;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }

        &-numbers {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            width: 100%;

            &-item {
                background: #ffffff;
                border-radius: 10px;
                padding: 10px;
            }
        }

        &-number {
            appearance: none;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border: 2px solid #000000;
            box-shadow: inset 5px 5px 4px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            box-sizing: border-box;

            font-family: "Montserrat";
            font-weight: 700;
            font-size: 30px;
            line-height: 30px;
            text-align: center;

            color: #000000;
            caret-color: transparent;
        }

        &-phone {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 23px;
            background: #ffffff;
            border-radius: 15px;
            filter: drop-shadow(3px 4px 4px rgba(0, 0, 0, 0.35));
            border: none;
            box-sizing: border-box;
            font-family: "Montserrat";
            font-weight: 700;
            font-size: 30px;
            line-height: 37px;
            text-align: center;

            color: #000000;
            &::placeholder {
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.6);

                transform: translateY(-25%);
            }

            &:focus::placeholder {
                opacity: 0;
            }
        }

        &-error {
            opacity: 0;
            pointer-events: none;
            font-size: 18px;
            color: #ff1313;
            margin-top: 5px;
            margin-left: 25px;

            transition: opacity 250ms linear;
            &.active {
                opacity: 1;
            }
        }

        &-button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 9px 23px;
            background: #ffffff;
            border-radius: 25px;
            filter: drop-shadow(3px 4px 4px rgba(0, 0, 0, 0.35));
            border: none;
            box-sizing: border-box;

            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 43px;
            text-align: center;

            color: #000000;
            margin-top: 35px;
        }
    }

    &__code {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 35px;

        &-open {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            color: #000000;
            padding: 2px 18px;
            background: #ffffff;
            box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 15px;
            max-width: 215px;
        }

        &-send {
            opacity: 0;
            pointer-events: none;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            background: #ffffff;
            box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.4);
            border-radius: 15px;
            padding: 21px 20px;

            transition: opacity 250ms linear;

            &.active {
                opacity: 1;
                pointer-events: all;
            }

            &-title {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;

                color: #000000;
            }

            &-text {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;

                color: #000000;
            }

            &-button {
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;

                color: #000000;
                padding: 10px 25px;
                border: 2px solid #11bb02;
                box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
            }
        }
    }
    &__logo {
        position: fixed;
        bottom: 16px;
        right: 20px;

        display: flex;
    }
}
