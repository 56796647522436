@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

body {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0f84f0;

  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

button,
a {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  text-decoration: none;
}

img {
  display: block;
}

.qr-scan-block {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.qr-scan-content {
  width: 100%;
  position: sticky;
  z-index: 1;

  &-text {
    font-size: 30px;
    text-align: center;
    color: #00ab09;
    margin-bottom: -40px;
    height: 30px;
  }
}
.qr-scan-icon {
  width: 100%;
}

.qr-scan div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.qr-scan video {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
