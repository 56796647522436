@media screen and (min-width: 768px) {
    .wash {
        padding-top: 100px;
    }
}

.wash__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 125px;

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.wash__flex {
    display: none;

    @media screen and (min-width: 768px) {
        display: flex;
        gap: 50px;
    }
}

.wash__map {
    position: relative;
    width: 100%;

    border-radius: 50px;
    border: 20px solid #0f84f0;
    overflow: hidden;
    box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.25);

    & .maps-head {
        display: none;
    }
}

.wash__thoomb {
    min-width: 335px;
}

.wash__mob {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.wash__filter {
    position: relative;
    width: 100px;
    height: 35px;

    &-open {
        z-index: 2;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        width: 100px;
        height: 35px;
        border-radius: 10px;
        background: #ffffff;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    }

    &-text {
        position: sticky;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        z-index: 2;
    }

    &-buttons {
        position: absolute;
        top: 80%;
        left: 0;
        width: 100px;
        height: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ffffff;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
        border-radius: 0px 0px 10px 10px;
        z-index: 1;
        overflow: hidden;
        transition: height 250ms linear;

        &.active {
            height: 80px;
        }
    }

    &-button {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        z-index: -1;
    }
}

.wash__maps {
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #183b6b;
    background: #0f84f0;

    &-text {
        padding: 0 13px 0 11px;
        color: #fff;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.wash__list {
    padding: 125px 0;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    transition: opacity 250ms linear;
}

.wash__item {
    &-link {
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: #ffffff;
        border-radius: 20px;
        filter: drop-shadow(2px 5px 10px rgba(0, 0, 0, 0.4));

        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }

    &-image {
        position: relative;
        border-radius: 20px;
        width: 100%;
        height: 70px;
        overflow: hidden;

        @media screen and (min-width: 768px) {
            height: unset;
        }

        &-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-balance {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 5px 5px 1px 11px;
            background: #ffffff;
            border-radius: 20px 0px 0px 0px;
            &-text {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #11bb02;
            }
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 25px;
        padding-left: 15px;
        padding-bottom: 5px;

        @media screen and (min-width: 768px) {
            flex-direction: column;
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: 5px;
            &-title {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 21px;
                display: flex;
                align-items: center;

                color: #000000;
            }
            &-address {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 18px;
                display: flex;
                align-items: center;

                color: rgba(0, 0, 0, 0.5);
            }
        }

        &-addition {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-icon {
                padding: 5px;
                border: 2px solid #000000;
                border-radius: 10px;
            }

            &-text {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                display: flex;
                align-items: center;

                color: #000000;
            }
        }
    }
}

.wash__item-page {
    &-slider {
        width: 100%;

        &-img {
            width: 100%;

            @media screen and (min-width: 768px) {
                border-radius: 20px;
            }
        }
        &-text {
            position: absolute;
            top: 21px;
            right: 17px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;

            color: #000000;
            padding: 5px 8px;
            border: 2px solid #000000;
            border-radius: 15px;
            z-index: 2;
            pointer-events: none;
        }
    }

    &-info {
        &-balance {
            position: sticky;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border: 1px solid #000000;
            border-radius: 20px 20px 0px 0px;
            padding: 12px 18px 26px 18px;
            margin-top: -20px;
            z-index: 1;
            &-text {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;

                color: #000000;
            }

            &-price {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 37px;

                color: #11bb02;
            }
        }

        &-content {
            position: sticky;
            background: #ffffff;
            border: 1px solid #000000;
            border-radius: 20px 20px 0px 0px;
            padding: 24px;
            margin-top: -25px;
            z-index: 2;

            &-block {
                display: flex;
            }

            &-description {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            &-image {
                display: none;

                &-text {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;

                    color: #000000;
                }
            }

            &-desc {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;

                color: #000000;
                margin-top: 30px;
                margin-bottom: 25px;
            }

            &-deposit,
            &-road {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 12px 0;

                color: #ffffff;
                background: #000000;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
                border-radius: 20px;
                filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.4));
                width: 100%;
                margin-bottom: 20px;
                cursor: pointer;
            }

            &-road {
                margin-top: 20px;
            }
        }
    }

    &-back {
        position: absolute;
        top: 21px;
        left: 17px;
        width: 30px;
        height: 30px;
        z-index: 3;

        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}
