.maps {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    overflow: hidden;

    &-head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 65px;
        border-radius: 0px 0px 20px 20px;
        background: #005eb6;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-back {
            margin-left: 20px;
        }

        &-filter {
            margin-right: 16px;

            &-button {
                padding: 7px 12px;
                border-radius: 15px;
                border: 2px solid #fff;

                color: #fff;
                font-family: Montserrat;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

    &-control {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 23px;

        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        z-index: 10000;
        &-plus {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 35px;
            height: 35px;

            border-radius: 50%;
            background: #0f83ef;

            &-icon {
                width: 20px;
                height: 20px;
            }
        }

        &-minus {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 35px;
            height: 35px;

            border-radius: 50%;
            background: #0f83ef;

            &-icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    &-current {
        position: absolute;
        bottom: 27px;
        right: 18px;
        z-index: 10000;
        color: #fff;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 7px 10px 8px 16px;
        border-radius: 15px;
        background: #0f83ef;
    }

    &-filter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        z-index: 3;

        border-radius: 20px 20px 0px 0px;
        background: #183b6b;

        opacity: 0;
        pointer-events: none;
        transform: translateY(100%);

        transition: opacity 250ms linear;
        transition-property: opacity, transform;

        &.active {
            transform: translateY(0);
            pointer-events: all;
            opacity: 1;
        }

        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px 27px 9px 27px;

            &-title {
                color: #fff;
                font-family: Montserrat;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        &-categories {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 12px;
            padding: 13px 27px;

            @media screen and (min-width: 768px) {
                justify-content: flex-start;
            }

            &-button {
                display: flex;
                align-items: center;
                gap: 7px;
                padding: 6px 21px;
                border-radius: 15px;
                border: 2px solid #005eb6;

                color: #fff;
                font-family: Montserrat;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                &.active {
                    background: #005eb6;
                }
            }
        }

        &-result {
            padding: 0 28px 22px 28px;
            &-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 9px 0;

                border-radius: 15px;
                background: #fff;
                box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.45);

                color: #000;
                font-family: Montserrat;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

    &-popup {
        & &-address {
            margin: 0;
            margin-bottom: 3px;
        }
        & &-time {
            margin: 0;
            margin-bottom: 10px;
        }
        &-address {
            color: #fff;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        &-time {
            color: #fff;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            &-open {
                color: #00ff00;
            }
            &-close {
                color: #ffeb00;
            }
        }

        &-categories {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: space-between;

            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2px 6px;
                color: #fff;
                font-family: Montserrat;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-radius: 15px;
                border: 2px solid white;
            }
        }
    }
}

.leaflet-popup-content-wrapper {
    background: #005eb6;
    box-shadow: none;
}

.leaflet-popup-tip {
    background: #005eb6;
}

.leaflet-popup-close-button span {
    color: white;
}
