.only-mobile {
    display: none;
}

@media screen and (min-width: 450px) {
    .only-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0f84f0;
        z-index: 15;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        &-icon {
            width: 100px;
            height: 100px;
        }

        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 21px;
            display: flex;
            align-items: center;

            color: #000000;
        }
    }
}
